<template>
    <div class="menu-container">
        <el-menu class="app-menu" :default-active="activeMenu" mode="horizontal" :router="true" :unique-opened="false">
            <template v-for="v in menuList">
                <el-menu-item :index="v.path" >{{v.meta.name}}</el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '../../router/index'

export default {
    name: "AppMenu",
    computed: {
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
    },
    data() {
        return {
            menuList: this.menuList,
        }
    },
    created() {
        let all_menuList  = [
            {"path":"/report/balance","meta":{"name":"现金流"}},
            {"path":"/report/gross-profit","meta":{"name":"关键指标"}},
            {"path":"/report/account-3c","meta":{"name":"3C管理"}},
            {"path":"/report/budget-manage","meta":{"name":"六项可控"}},
            {"path":"/report/fund-center","meta":{"name":"资金中台统计"}},
        ];

        let menu_names = this.$store.getters.menuList;
        let dy_menuList = [];
        all_menuList.forEach(function (menu) {
            console.log(menu.meta.name);
            menu_names.forEach(function (name) {
                if(name == menu.meta.name){
                    dy_menuList.push(menu);
                }
            })
        })
        this.menuList = dy_menuList;
        if(dy_menuList.length > 0 && this.$route.path === "/report"){
            this.$router.replace({path:dy_menuList[0].path});
        }
    },
    mounted() {
    },
    // methods: {
    //     gotoRoute(name) {
    //         this.$router.push({ name }); // push(path)  push({name:''})
    //     }
    // }
}
</script>


<style lang="scss" scoped>
.app-menu.el-menu {
    &.el-menu--horizontal {
        border: none;
        & >.el-menu-item {
            height: $navbar-height - 20px;
            line-height: $navbar-height - 20px;
            &.is-active {
                border-bottom: none;
                font-weight: bold;
            }
        }
    }
}
</style>

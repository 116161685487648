import Vue from "vue";
import Layout from '@/layout'
import Router from "vue-router";
import store from "../store/index"
import { getInfo, authLogin } from '@/api/user'

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/pages/index/index"),
    meta: { title: "主页" },
  },
  {
    path: '/report',
    component: Layout,
    // redirect: '/report/budget-manage',
    children: [
      {
        path: 'balance',
        component: () => import('@/pages/report/report'),
        name: '现金流',
        meta: {activeMenu: '/report/balance'}
      },
      {
        path: 'account-3c',
        component: () => import('@/pages/account-3c/index'),
        name: '3C管理',
      },
      {
        path: 'gross-profit',
        component: () => import('@/pages/gross-profit/index'),
        name: '关键指标',
      },
      {
        path: 'budget-manage',
        component: () => import('@/pages/budget/index'),
        name: '六项可控',
      },
      {
        path: 'fund-center',
        component: () => import('@/pages/fund-center/index'),
        name: '资金中台统计报表',
      }

    ]
  },

  {
    path: "/404",
    component: () => import("@/pages/error-page/404"),
    meta: { title: "页面不存在" },
  },
  {
    path: "/401",
    component: () => import("@/pages/error-page/401"),
    meta: { title: "没有权限" },
  },
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    // mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

<template>
    <div class="app-navbar">
        <div class="left-container navbar-item">
            <Logo></Logo>
        </div>
        <div class="navbar-container navbar-item">
            <AppMenu></AppMenu>
        </div>

        <div class="right-container navbar-item">
            <SwitchCompany></SwitchCompany>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu.vue'
import Logo from './Logo.vue'
import SwitchCompany from './SwitchCompany.vue'

export default {
    name: "Navbar",
    components: {
        AppMenu,
        Logo,
        SwitchCompany
    },
    data() {
        return {

        }
    },
    created() { },
    mounted() { }
}
</script>


<style lang="scss" scoped>
</style>
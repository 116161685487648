import request from "@/utils/request";

/**
 * "每日账户余额变化趋势数据": /api/bi/leate_account_balance
 */
export function leate_account_balance(token) {
    var data = { token: token };
    return request({
        url: "/bi/leate_account_balance",
        method: "post",
        data,
    });
}

/**
 * "每日现金流入流出情况": /api/bi/leate_account_balance_result
 */
export function getleate_account_balance_resultInfo(token) {
    var data = { token: token };
    return request({
        url: "/bi/leate_account_balance_result",
        method: "post",
        data,
    });
}

/**
 * "本月累计流入占比": /api/bi/current_month_in
 */
export function get_current_month_in(token) {
    var data = { token: token };
    return request({
        url: "/bi/current_month_in",
        method: "post",
        data,
    });
}

/**
 * "本月累计流出占比": /api/bi/current_month_out
 */
export function get_current_month_out(token) {
    var data = { token: token };
    return request({
        url: "/bi/current_month_out",
        method: "post",
        data,
    });
}

/**
 * "各板块余额情况": /api/bi/
 * 余额变化汇总：/api/bi/account_balance_detail
余额变化明细：/api/bi/account_balance_detail_lines

流水变化汇总：/api/bi/account_balance_result_detail
流水变化明细：/api/bi/account_balance_result_detail_line
 */
export function get_section_balance_data(date) {
    var data = { start: date[0], end: date[1] };
    return request({
        url: "/bi/account_detail/first",
        method: "post",
        data,
    });
}

/**
 * "各版块下面的子模块余额情况": /api/bi/
 * category 板块
 * section 区域
 */
export function get_sub_section_balance_data(plate_name, category, date) {
    var data = { category: plate_name, section: category, start: date[0], end: date[1] };

    return request({
        url: "/bi/account_detail/second",
        method: "post",
        data,
    });
}

/**
 * "各版块下面的第三级下拉模块余额情况": /api/bi/
 * category 板块
 * section 区域
 */
export function get_third_section_balance_data(plate_name, category, company_name, date) {
    var data = { category: plate_name, section: category, company_name: company_name, start: date[0], end: date[1] };

    return request({
        url: "/bi/account_detail/third",
        method: "post",
        data,
    });
}

export function get_fourth_section_balance_data(category, plate_name, company_name, child_company_name, date) {
    var data = {
        category: category, //板块
        section: plate_name,
        company_name: company_name,
        child_company_name: child_company_name,
        start: date[0],
        end: date[1]
    };
    return request({
        url: "/bi/account_detail/fourth",
        method: "post",
        data,
    });
}

// 各版块资金流入明细

export function get_section_amountin_data(date) {
    var data = {
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/amountin_detail/first",
        method: "post",
        data
    })
}
export function get_sub_section_amountin_data(category, section, date) {
    var data = {
        category: category,
        section: section,
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/amountin_detail/second",
        method: "post",
        data
    })
}
export function get_third_section_amountin_data(category, section, company_name, date) {
    var data = {
        category: category,
        section: section,
        company_name: company_name,
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/amountin_detail/third",
        method: "post",
        data
    })
}


// 切换公司
export function switch_category_api(category) {
    var data = { category: category };
    return request({
        url: "/switch_category",
        method: "post",
        data,
    });
}

export function switch_company_api(company_id) {
    var data = { company_id: company_id };
    return request({
        url: "/switch_company ",
        method: "post",
        data,
    });
}


/**
 * 每日账户净流入变化趋势
 */
export function current_month_net_inflow() {
    return request({
        url: "/bi/current_month_net_inflow",
        method: "post",
    });
}


/**
 * 资产负债表，利润表，现金流量表 报表
 */
 export function account3Cdata(data) {
    return request({
        url: "/bi/account_3c",
        method: "post",
        data
    });
}


// 毛利统计
export function get_section_profit_data(date) {
    var data = {
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/account_profit",
        method: "post",
        data
    })
}

// 现金流 table 数据
export function get_cash_flow_table(date) {
    var data = {
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/cash_flow_table",
        method: "post",
        data
    })
}


/**
 * "毛利统计页面： 各期毛利折线图对比 ": /api/bi/get_profit_line
 */
export function get_profit_line() {
    return request({
        url: "/bi/get_profit_line",
        method: "post",
    });
}

/**
 * "毛利统计页面： 各期毛利折线图对比 ": /api/bi/get_cash_flow_line
 */
export function get_cash_flow_line() {
    return request({
        url: "/bi/get_cash_flow_line",
        method: "post",
    });
}


// 六项可控预算table数据
export function get_section_budget_table(date, budget_level_one, budget_level_two) {
    console.log('date=',date, budget_level_one, budget_level_two);
    var data = {
        start: date[0],
        end: date[1],
        level_one: budget_level_one,
        level_two: budget_level_two
    };
    return request({
        url: "/bi/budget_table",
        method: "post",
        data
    });
}


// 资金中台统计 数据
export function get_fund_center_data(date) {
    var data = {
        start: date[0],
        end: date[1]
    }
    return request({
        url: "/bi/get_fund_center_data",
        method: "post",
        data
    })
}

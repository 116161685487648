import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import store from './store'
import router from './router'

import './styles/index.scss'
import './icons' // icon
import './permission'
import 'lib-flexible'
// import 'lib-flexible/flexible.js'

Vue.use(Element, {
    size: 'medium'
})

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')

import { switch_category_api, switch_company_api } from '@/api/report'

const state = {
    category: "",
    categoryList: [],
    company: "",
    companyList: [],
    menuList: []
}

const mutations = {
    SET_CATEGORY: (state, category) => {
        state.category = category
    },
    SET_CATEGORYLIST: (state, categoryList) => {
        state.categoryList = categoryList
    },
    SET_COMPANY: (state, company) => {
        state.company = company
    },
    SET_COMPANYLIST: (state, companyList) => {
        state.companyList = companyList
    },
    SET_MENU: (state, menuList) => {
        state.menuList = menuList
    }
}

const actions = {
    initApp({ commit }, userInfo) {
        console.log(userInfo);
        commit('SET_CATEGORY', userInfo.category)
        commit('SET_CATEGORYLIST', userInfo.categories)
        commit('SET_COMPANY', userInfo.company_id)
        commit('SET_COMPANYLIST', userInfo.company_ids)
        commit('SET_MENU', userInfo.menus)
    },
    switchCategory({ commit, state }, category) {
        if(state.category == category) {
            return false
        }
        return switch_category_api(category).then(res => {
            if (res.code === 0) {
                var companyList = res.data
                if(companyList.length == 1) {
                    return true
                }
                commit('SET_CATEGORY', category)
                commit('SET_COMPANY', "")
                commit('SET_COMPANYLIST', companyList)
                return false
            }
        })
    },
    switchCompany({ commit, state }, company) {
        if(state.company == company) {
            return false
        }
        return switch_company_api(company).then(res => {
            if (res.code === 0) {
                commit('SET_COMPANY', company)
                return true
            }
            return false
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

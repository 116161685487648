<template>
    <div class="switch-company">
        <el-select v-model="selectCategory" class="change-item" placeholder="板块" filterable @change="changeCategory">
            <el-option v-for="(item, index) in categoryList" :key="index" :label="item" :value="item">
            </el-option>
        </el-select>

        <el-select v-model="selectCompany" class="change-item" placeholder="公司" style="margin-left: 50px;width: 100px;"
            filterable @change="changeCompany">
            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "SwitchCompany",
    computed: {
        ...mapGetters([
            'category',
            'company',
            'companyList',
            'categoryList'
        ]),
    },
    data() {
        return {
            selectCompany: this.company,
            selectCategory: this.category
        }
    },
    watch: {
        company(val, old) {
            this.selectCompany = val
        }
    },
    mounted() {
        //窗口尺寸改变
        var oldwidth = window.innerWidth
        window.addEventListener('resize', () => {
            if (Math.abs(oldwidth - window.innerWidth) > 200) {
                this.Refresh()
            }
        }),
            this.selectCompany = this.company,
            this.selectCategory = this.category
    },
    methods: {
        changeCategory(category) {
            this.$store.dispatch('app/switchCategory', category).then(refresh => {
                if (refresh) {
                    this.Refresh()
                }
            })
        },
        changeCompany(company) {
            this.$store.dispatch('app/switchCompany', company).then(refresh => {
                if (refresh) {
                    this.Refresh()
                }
            })
        },
        // 刷新
        Refresh() {
            this.$router.go(0)
        }
    }
}
</script>


<style lang="scss" scoped>
.switch-company {
    padding-right: 40px;
    height: $navbar-height - 20px;
    line-height: $navbar-height - 20px;



}
</style>

<style lang="scss">
.el-select.change-item {
    .el-input .el-input__inner {
        border: none;
    }

    .el-input--medium .el-input__inner {
        height: 36px;
        line-height: 36px;
    }

    .el-input--medium .el-input__icon {
        line-height: 36px;
    }
}
</style>

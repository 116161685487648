<template>
    <div class="logo">
        <router-link key="collapselogo" class="sidebar-logo-link" to="/">
            <img :src="logo" class="sidebar-logo">
        </router-link>
    </div>
</template>

<script>
import logoImage from '@/assets/logo_v2.png'

export default {
    name: "Logo",
    data() {
        return {
            logo:  logoImage
        }
    },
    created() { },
    mounted() { }
}
</script>


<style lang="scss" scoped>
.logo {
    width: 100%;
    height: 50px;
    img {
        height: 50px;
    }
}
</style>
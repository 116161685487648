<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setHtmlSizeByDeviceWidth } from '@/assets/remfont.js'

export default {
  name: 'App',
  // mounted() {
  //   setHtmlSizeByDeviceWidth()
  // },
}
</script>

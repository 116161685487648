<template>
    <div class="app-wrapper">
        <Navbar />
        <div class="main-container">
            <AppMain />
        </div>
    </div>
</template>

<script>
import AppMain from './components/AppMain.vue'
import Navbar from './components/Navbar.vue'

export default {
    name: "Layout",
    components: {
        AppMain,
        Navbar
    },
    computed: {
        key() {
            return this.$route.path
        }
    },
    data() {
        return {

        }
    },
    created() { },
    mounted() { }
}
</script>


<style lang="scss" scoped>
</style>
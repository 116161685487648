<template>
    <section class="app-main" :style="background">
        <transition name="fade-transform" mode="out-in">
            <keep-alive>
                <router-view :key="key" />
            </keep-alive>
        </transition>
    </section>
</template>

<script>

export default {
    name: "AppMain",
    data() {
        return {
            // 顶部导航背景图片配置
            background: {
                // 背景图片地址
                backgroundImage: 'url(' + require('@/assets/backgroud.jpg') + ')',
                // 背景图片是否重复
                backgroundRepeat: 'no-repeat',
                // 背景图片大小
                backgroundSize: 'cover',
                // 背景颜色
                backgroundColor: '#000',
                // 背景图片位置
                backgroundPosition: 'center top',
            },
        }
    },
    created() { },
    mounted() { }
}
</script>


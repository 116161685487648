import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/', '/401']

//全局路由拦截器
router.beforeEach(async(to, from, next) => {
    NProgress.start()

    document.title = getPageTitle(to.meta.title)
    if (whiteList.indexOf(to.path) !== -1) {
        next()
    } else {
        const hasToken = getToken()
        if (hasToken) {
            const userInfo = store.getters.userInfo
            if (userInfo) {
                next()
            } else {
                try {
                    await store.dispatch('user/userInfo')
                    next()
                } catch (error) {
                    Message.error("获取用户信息发生错误")
                    NProgress.done()
                }
            }
        } else {
            Message.error("你还没有登录请登录后再试一试")
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})

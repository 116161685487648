const getters = {
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    category: state => state.app.category,
    company: state => state.app.company,
    companyList: state => state.app.companyList,
    categoryList: state => state.app.categoryList,
    menuList: state => state.app.menuList,
}
export default getters

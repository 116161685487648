import { getInfo, authLogin } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
    token: getToken(),
    userInfo: undefined,
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    }
}

const actions = {
    // user login
    authLogin({ commit }, code) {
        return new Promise((resolve, reject) => {
            authLogin(code).then(response => {
                const { data } = response
                commit('SET_TOKEN', data)
                setToken(data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    userInfo({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(response => {
                const { data } = response

                if (!data) {
                    reject('验证失败，请重新登录！')
                }
                dispatch('app/initApp', data, {root: true})
                commit('SET_USERINFO', data)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

const service = axios.create({
    baseURL: "/api", // url = base url + request url
    timeout: 20000*10 // request timeout
})

service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['X-Token'] = getToken()
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data

        if (res.code !== 0) {
            Message({
                message: res.msg || '未知错误',
                type: 'error',
                duration: 5 * 1000
            })

            if (res.code === -100) {
                MessageBox.confirm('Session 过期请重新登录', '登出确认', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
            }
            return Promise.reject(new Error(res.msg || '未知错误'))
        } else {
            return res
        }
    },
    error => {
        console.log(error)
        Message({
            message: error.msg || "服务器内部错误",
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service

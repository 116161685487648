import request from '@/utils/request'

export function getInfo(token) {
    return request({
        url: '/user/info',
        method: 'get',
        params: { token }
    })
}

export function authLogin(code) {
    var data = {code: code}
    return request({
        url: '/saas_erp/auth_login',
        method: 'post',
        data
    })
}
